import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import GetNotifiedBlock from "../components/Shop/GetNotifiedBlock"
import ProductLandingPageBlock from "../components/Shop/ProductLandingPageBlock"
import colors from "../styles/colors"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"

const PageContainer = styled.div`
  ${'' /* padding-top: calc(2vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax; */}
  padding: calc(4vmax + 94px) 5vw 6.6vmax;
  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + 40px);
    ${'' /* padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px); */}
  }
`

const Icon = styled.img`
  width: 20px
`
const Legend = styled.div`
  text-align: center;
  position: relative;
  margin-top: 3vw;
  
`
const ShopPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulShopPage
  const vegetarianIcon = page.vegetarianIcon;
  const glutenIcon = page.glutenIcon;
  
  const saleOn = true;// page.drops

  let sharingImage = false

  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }  
 
  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle} 
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        {saleOn ? <ProductLandingPageBlock data={data} /> : <GetNotifiedBlock blocks={page.blocks} />}
    
        <Legend>
      <Icon src={vegetarianIcon.file.url}></Icon>Has Vegetarian Option
      <br/>
      <Icon src={glutenIcon.file.url}></Icon>Gluten Free
      </Legend>
      
      </PageContainer>
     
    </Layout>
  )
}

export default ShopPage

export const pageQuery = graphql`
  query ShopQuery($category_id: [SwellProductFieldsEnum]!) {
    contentfulShopPage(slug: { eq: "shop" }) {
      ...shopPageFragment
    }
    allSwellProduct(
      filter: { active: { eq: true } }
      sort: { fields: $category_id }
    ) {
      edges {
        node {
          price
          currency
          name
          slug
          stock_status
          stock_level
          options {
            values {
              name
              price

              id
            }
            name
          }
          images {
            fileLocal {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.76)
              }
            }
          }
          categories {
            slug
          }
          sale_price
          sale
        }
      }
    }
    allContentfulProduct(filter: {}) {
      edges {
        node {
          title
          slug
          hasVegetarian
          isGlutenFree
          servingSize
          newRelease
          quickCook
          spicy
          blocks {
            __typename
            ... on ContentfulRestaurant {
              name
            }
          }
        }
      }
    }
  }
`
